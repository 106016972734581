<template>
  <div>
    <Echart id="centreLeft2Chart" ref="centreLeft2ChartRef" :options="options" :height="height" :width="width" />
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data() {
    return {
      options: {}
    }
  },
  components: {
    Echart
  },
  props: {
    cdata: {
      type: Array,
      default: () => []
    },
    height: {
      type: String,
      default: () => '500px'
    },
    width: {
      type: String,
      default: () => '100%'
    }
  },
  watch: {
    cdata: {
      handler(newData) {
        //设置点的位置(经纬度)
        const geoCoordMap = {
          碧螺塔海上酒吧公园: [119.542041, 39.826621],
          昌黎国际滑沙中心: [119.31487, 39.642311],
          公主号游船: [119.630369, 39.919438],
          左右山谷: [119.077576, 39.820339],
          傍水崖景区: [119.57862, 40.138537]
        }
        let seriesData = [
          {
            name: '碧螺塔海上酒吧公园'
          },
          {
            name: '昌黎国际滑沙中心'
          },
          {
            name: '公主号游船'
          },
          {
            name: '左右山谷'
          },
          {
            name: '傍水崖景区'
          }
        ]
        let convertData = function (data) {
          let scatterData = []
          for (var i = 0; i < data.length; i++) {
            var geoCoord = geoCoordMap[data[i].name]
            if (geoCoord) {
              scatterData.push({
                name: data[i].name,
                value: geoCoord.concat(data[i].value)
              })
            }
          }
          return scatterData
        }
        this.options = {
          showLegendSymbol: true,
          tooltip: {
            trigger: 'item',
            textStyle: {
              fontSize: 14,
              lineHeight: 22
            },
            position: (point) => {
              // 固定在顶部
              return [point[0] + 50, point[1] - 20]
            },
            // 如果需要自定义 tooltip样式，需要使用formatter
            formatter: (params) => {
              return `<div style="font-weight: bold;font-size: 16px;color: #3de7c9"> ${params.data.name} </div>
                  <div style="padding-top: 5px;"> <span>坐标：${params.data.value}</span><br/><span>景区状态：${
                params.data.upnum ? '闭园' : '开园'
              }</span></div>`
            }
          },
          visualMap: {
            min: 0,
            max: 10,
            show: false,
            seriesIndex: 0,
            // 颜色
            inRange: {
              color: ['rgba(41,166,206, .5)', 'rgba(69,117,245, .9)']
            }
          },
          // 底部背景
          geo: {
            show: false,
            aspectScale: 0.85, //长宽比
            zoom: 1.2,
            top: '10%',
            left: '10%',
            map: '秦皇岛市',
            roam: false,
            itemStyle: {
              normal: {
                areaColor: 'rgba(0,0,0,0)',
                shadowColor: 'rgba(7,114,204, .8)',
                shadowOffsetX: 5,
                shadowOffsetY: 5
              },
              emphasis: {
                areaColor: '#00aeef'
              }
            }
          },
          series: [
            {
              name: '相关指数',
              type: 'map',
              aspectScale: 0.85, //长宽比
              zoom: 1.2,
              mapType: '秦皇岛市', // 自定义扩展图表类型
              top: '10%',
              left: '10%',
              itemStyle: {
                normal: {
                  color: 'red',
                  areaColor: 'rgba(19,54,162, .5)',
                  borderColor: 'rgba(0,242,252,.3)',
                  borderWidth: 1,
                  shadowBlur: 7,
                  shadowColor: '#00f2fc'
                },
                emphasis: {
                  areaColor: '#4f7fff',
                  borderColor: 'rgba(0,242,252,.6)',
                  borderWidth: 2,
                  shadowBlur: 10,
                  shadowColor: '#00f2fc'
                }
              },
              label: {
                formatter: (params) => `${params.name}`,
                show: true,
                position: 'insideRight',
                textStyle: {
                  fontSize: 14,
                  color: '#efefef'
                },
                emphasis: {
                  textStyle: {
                    color: '#fff'
                  }
                }
              },

              data: newData
              // markPoint: {
              //   type: 'effectScatter',
              //   coordinateSystem: 'geo',
              //   symbolSize: 7,
              //   effectType: 'ripple',
              //   legendHoverLink: false,
              //   showEffectOn: 'render',
              //   rippleEffect: {
              //     period: 4,
              //     scale: 2.5,
              //     brushType: 'stroke'
              //   },
              //   zlevel: 1,
              //   itemStyle: {
              //     normal: {
              //       color: '#99FBFE',
              //       shadowBlur: 5,
              //       shadowColor: '#fff'
              //     }
              //   },
              //   symbol: 'circle', // 自定义图片路径
              //   // itemStyle: {
              //   //   normal: {
              //   //     color: 'red' // smbol 颜色 默认红色
              //   //   }
              //   // },
              //   // symbolSize: [5, 5], // 图片大小
              //   label: {
              //     position: 'top',
              //     color: '#3de7c9',
              //     fontSize: 8
              //   },
              //   // // coord数组存放地址坐标
              //   data: convertData(seriesData)
              // },
            },
            {
              type: 'effectScatter',
              coordinateSystem: 'geo',

              symbolSize: 7,
              effectType: 'ripple',
              legendHoverLink: false,
              showEffectOn: 'render',
              label: {
                normal: {
                  color: '#3de7c9',
                  show: true,
                  fontSize: 9,
                  offset: [0, -12], // 字体向上偏移10个像素
                  formatter: '{b}' // 显示坐标轴的值
                }
              },
              rippleEffect: {
                period: 4,
                scale: 2.5,
                brushType: 'stroke'
              },
              zlevel: 1,
              itemStyle: {
                normal: {
                  color: '#99FBFE',
                  shadowBlur: 5,
                  shadowColor: '#fff'
                }
              },
              data: convertData(seriesData)
            }
          ]
        }
        //重新选择区域
        this.handleMapRandomSelect()
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    // this.initOptions()
  },
  methods: {
    // initOptions() {
    //   this.options = {
    //     showLegendSymbol: true,
    //     tooltip: {
    //       trigger: 'item',
    //       textStyle: {
    //         fontSize: 14,
    //         lineHeight: 22
    //       },
    //       position: (point) => {
    //         // 固定在顶部
    //         return [point[0] + 50, point[1] - 20]
    //       },
    //       // 如果需要自定义 tooltip样式，需要使用formatter
    //       formatter: (params) => {
    //         return `<div style="font-weight: bold;font-size: 16px;color: #3de7c9"> ${params.data.name} </div>
    //                     <div style="padding-top: 5px;"> <span>景区名称：${params.data.value}</span><br/><span>景区状态：${
    //           params.data.upnum ? '开园' : '闭园'
    //         }</span></div>`
    //       }
    //     },
    //     visualMap: {
    //       min: 0,
    //       max: 10,
    //       show: false,
    //       seriesIndex: 0,
    //       // 颜色
    //       inRange: {
    //         color: ['rgba(41,166,206, .5)', 'rgba(69,117,245, .9)']
    //       }
    //     },
    //     // 底部背景
    //     geo: {
    //       show: false,
    //       aspectScale: 0.85, //长宽比
    //       zoom: 1.2,
    //       top: '10%',
    //       left: '10%',
    //       map: '秦皇岛市',
    //       roam: false,
    //       itemStyle: {
    //         normal: {
    //           areaColor: 'rgba(0,0,0,0)',
    //           shadowColor: 'rgba(7,114,204, .8)',
    //           shadowOffsetX: 5,
    //           shadowOffsetY: 5
    //         },
    //         emphasis: {
    //           areaColor: '#00aeef'
    //         }
    //       }
    //     },
    //     series: [
    //       {
    //         name: '相关指数',
    //         type: 'map',
    //         aspectScale: 0.85, //长宽比
    //         zoom: 1.2,
    //         mapType: '秦皇岛市', // 自定义扩展图表类型
    //         top: '20%',
    //         left: '10%',
    //         markPoint: {
    //           symbol: 'circle', // 自定义图片路径
    //           itemStyle: {
    //             normal: {
    //               color: 'red' // smbol 颜色 默认红色
    //             }
    //           },
    //           symbolSize: [5, 5], // 图片大小
    //           label: {
    //             position: 'top',
    //             color: '#3de7c9',
    //             fontSize: 8
    //           },
    //           // // coord数组存放地址坐标
    //           data: [
    //             { name: '秦皇岛市', coord: [119.542041, 39.826621], value: '碧螺塔海上酒吧公园', upnum: 1 },
    //             { name: '秦皇岛市', coord: [119.31487, 39.642311], value: '昌黎国际滑沙中心', upnum: 0 },
    //             { name: '秦皇岛市', coord: [119.630369, 39.919438], value: '公主号游船', upnum: 1 },
    //             { name: '秦皇岛市', coord: [119.077576, 39.820339], value: '左右山谷', upnum: 0 },
    //             { name: '秦皇岛市', coord: [119.57862, 40.138537], value: '傍水崖景区', upnum: 1 }
    //             // { name: '宽城满族自治县', coord: [118.337732, 40.627351], value: '清河源高山峡谷漂流' }
    //             // { name: '连江县', coord: [119.572573, 26.201361], value: '37' },
    //             // { name: '长乐区', coord: [119.533479, 25.968762], value: '50' }
    //           ]
    //         },
    //         itemStyle: {
    //           normal: {
    //             color: 'red',
    //             areaColor: 'rgba(19,54,162, .5)',
    //             borderColor: 'rgba(0,242,252,.3)',
    //             borderWidth: 1,
    //             shadowBlur: 7,
    //             shadowColor: '#00f2fc'
    //           },
    //           emphasis: {
    //             areaColor: '#4f7fff',
    //             borderColor: 'rgba(0,242,252,.6)',
    //             borderWidth: 2,
    //             shadowBlur: 10,
    //             shadowColor: '#00f2fc'
    //           }
    //         },
    //         label: {
    //           formatter: (params) => `${params.name}`,
    //           show: true,
    //           position: 'insideRight',
    //           textStyle: {
    //             fontSize: 14,
    //             color: '#efefef'
    //           },
    //           emphasis: {
    //             textStyle: {
    //               color: '#fff'
    //             }
    //           }
    //         },
    //         data: this.cdata
    //       },
    //       {
    //         type: 'effectScatter',
    //         coordinateSystem: 'geo',
    //         symbolSize: 7,
    //         effectType: 'ripple',
    //         legendHoverLink: false,
    //         showEffectOn: 'render',
    //         rippleEffect: {
    //           period: 4,
    //           scale: 2.5,
    //           brushType: 'stroke'
    //         },
    //         zlevel: 1,
    //         itemStyle: {
    //           normal: {
    //             color: '#99FBFE',
    //             shadowBlur: 5,
    //             shadowColor: '#fff'
    //           }
    //         }
    //         // data: convertData(seriesData)
    //       }
    //     ]
    //   }
    // }
    // 开启定时器
    // startInterval() {
    //   const _self = this
    //   // 应通过接口获取配置时间，暂时写死5s
    //   const time = 2000
    //   if (this.intervalId !== null) {
    //     clearInterval(this.intervalId)
    //   }
    //   this.intervalId = setInterval(() => {
    //     _self.reSelectMapRandomArea()
    //   }, time)
    // },
    // 重新随机选中地图区域
    // reSelectMapRandomArea() {
    //   const length = 9
    //   this.$nextTick(() => {
    //     try {
    //       const map = this.$refs.centreLeft2ChartRef.chart
    //       let index = Math.floor(Math.random() * length)
    //       while (index === this.preSelectMapIndex || index >= length) {
    //         index = Math.floor(Math.random() * length)
    //       }
    //       map.dispatchAction({
    //         type: 'mapUnSelect',
    //         seriesIndex: 0,
    //         dataIndex: this.preSelectMapIndex
    //       })
    //       map.dispatchAction({
    //         type: 'showTip',
    //         seriesIndex: 0,
    //         dataIndex: index
    //       })
    //       map.dispatchAction({
    //         type: 'mapSelect',
    //         seriesIndex: 0,
    //         dataIndex: index
    //       })
    //       this.preSelectMapIndex = index
    //     } catch (error) {
    //       console.log(error)
    //     }
    //   })
    // },
    // handleMapRandomSelect() {
    //   this.$nextTick(() => {
    //     try {
    //       const map = this.$refs.centreLeft2ChartRef.chart
    //       const _self = this
    //       setTimeout(() => {
    //         _self.reSelectMapRandomArea()
    //       }, 0)
    //       // 移入区域，清除定时器、取消之前选中并选中当前
    //       map.on('mouseover', function (params) {
    //         clearInterval(_self.intervalId)
    //         map.dispatchAction({
    //           type: 'mapUnSelect',
    //           seriesIndex: 0,
    //           dataIndex: _self.preSelectMapIndex
    //         })
    //         map.dispatchAction({
    //           type: 'mapSelect',
    //           seriesIndex: 0,
    //           dataIndex: params.dataIndex
    //         })
    //         _self.preSelectMapIndex = params.dataIndex
    //       })
    //       // 移出区域重新随机选中地图区域，并开启定时器
    //       map.on('globalout', function () {
    //         _self.reSelectMapRandomArea()
    //         _self.startInterval()
    //       })
    //       this.startInterval()
    //     } catch (error) {
    //       console.log(error)
    //     }
    //   })
    // }
  }
}
</script>
