<template>
  <Row class="page3">
    <Col style="width: 26%">
      <div class="left">
        <span class="title"><span class="title-text">迁安市合作景区</span></span>
        <span class="angle1"></span>
        <span class="angle2"></span>
        <span class="angle3"></span>
        <span class="angle4"></span>
        <div class="left1" style="height: 25%">
          <div style="height: 60%; padding-top: 2.5rem">
            <Row type="flex" justify="center" align="middle">
              <Col>
                <Row type="flex" justify="space-around" align="middle">
                  <!-- <Col>
                    <Icon type="md-home" size="50" color="#3de7c9" />
                  </Col> -->
                  <Col>
                    <div style="color: #f5f5f5; font-weight: bold; font-size: 18px">合作景区：</div>
                  </Col>
                </Row>
              </Col>
              <Col>
                <dv-digital-flop :config="config" style="width: 100px; height: 30px" />
              </Col>
            </Row>
            <br />
            <Row type="flex" justify="center" align="middle">
              <Col>
                <Row type="flex" justify="space-around" align="middle">
                  <!-- <Col>
                    <Icon type="ios-open" size="50" color="#3de7c9" />
                  </Col> -->
                  <Col>
                    <div style="color: #f5f5f5; font-weight: bold; font-size: 18px">开园景区：</div>
                  </Col>
                </Row>
              </Col>
              <Col>
                <dv-digital-flop :config="config1" style="width: 100px; height: 30px" />
              </Col>
            </Row>
            <!--                        <channel-bar
                            id="left_1"
                            title="生产企业"
                            :data="data1"
                            ref="channelBar1"
                        ></channel-bar>-->
            <!--                        <distribution-solider
                            id="left_2"
                            :data="data2"
                            ref="distributionSolider1"
                        ></distribution-solider>-->
          </div>
          <!--                    <div style="height: 35%;display: flex">
                        <div style="height: 100%; width: 33.33%;">
                            <pie ref="chart3" id="pie_1" :data="pieData1"></pie>
                        </div>
                        <div style="height: 100%; width: 33.33%;">
                            <pie ref="chart3" id="pie_3" :data="pieData2"></pie>
                        </div>
                        <div style="height: 100%; width: 33.33%;">
                            <pie ref="chart3" id="pie_2" :data="pieData3"></pie>
                        </div>
                    </div>-->
        </div>
        <div class="left1" style="height: 35%">
          <Row :gutter="60" type="flex" justify="space-around" align="middle" style="padding-top: 1rem">
            <Col :span="12" style="text-align: center"> <Icon type="ios-people" size="80" color="#3de7c9" /> </Col>
            <Col :span="12" style="text-align: center"> <Icon type="ios-person" size="80" color="#3de7c9" /> </Col>
          </Row>
          <Row :gutter="80" type="flex" justify="space-around" align="middle" style="padding: 0.5rem">
            <Col :span="12" style="text-align: center">
              <CountTo style="width: 100%; color: #3de7c9; font-size: 24px; font-weight: bold" :startVal="startVal" :endVal="endVal" :duration="duration" />
            </Col>
            <Col :span="12" style="text-align: center">
              <CountTo style="width: 100%; color: #3de7c9; font-size: 22px; font-weight: bold" :startVal="startVal" :endVal="endValLS" :duration="duration" />
            </Col>
          </Row>
          <Row :gutter="80" type="flex" justify="space-around" align="middle" style="padding: 0.5rem">
            <Col :span="12" style="text-align: center">
              <div style="width: 100%; color: #f5f5f5; font-weight: bold; font-size: 16px">会员数量</div>
            </Col>
            <Col :span="12" style="text-align: center">
              <div style="width: 100%; color: #f5f5f5; font-weight: bold; font-size: 16px">有效会员</div>
            </Col>
          </Row>
          <!-- <Row type="flex" justify="space-around" align="middle" style="padding-top: 1rem">
            <Col>
              <Row type="flex" justify="space-around" align="middle">
                <Col>
                  <Icon type="logo-foursquare" size="50" color="#3de7c9" />
                </Col>
                <Col>
                  <div style="color: #f5f5f5; font-weight: bold; font-size: 18px">种植企业</div>
                </Col>
              </Row>
            </Col>
            <Col>
              <dv-digital-flop :config="drugConfig" style="width: 100px; height: 30px" />
            </Col>
          </Row> -->
        </div>
        <div style="height: 50%">
          <div style="height: 90%">
            <span class="title"><span class="title-text">会员统计</span></span>
            <webBar1 ref="chart2" :data="dataBar"></webBar1>

            <!--                        <channel-bar
                            id="left_5"
                            title="炒股借贷渠道分析"
                            :data="data3"
                            ref="channelBar2"
                        ></channel-bar>-->
            <!--                        <distribution-solider
                            id="left_6"
                            :data="data4"
                            ref="distributionSolider2"
                        ></distribution-solider>-->
          </div>
          <!-- <div style="height:45%;"></div>-->
        </div>
      </div>
    </Col>
    <Col style="width: 48%; padding: 0 1%">
      <div class="center-top">
        <Echart :cdata="qdata" class="echart" />
        <Echart1 :cdata="qdata1" class="echart1" />
        <!-- <china-map ref="chinaMap"></china-map> -->
      </div>
      <!-- <div class="center-bottom">
        <span class="title"><span class="title-text">药品生产进出场统计</span></span>
        <span class="angle1"></span>
        <span class="angle2"></span>
        <span class="angle3"></span>
        <span class="angle4"></span>
        <bar-chart ref="chart3" id="left_3" :data="configData2"></bar-chart>
        <Row class="bottom-radars">
          <Col span="6">
            <radar id="bottom_1_1" :data="data5" ref="chart1"></radar>
          </Col>
          <Col span="6">
            <radar id="bottom_1_2" :data="data6" ref="chart2"></radar>
          </Col>
          <Col span="6">
            <radar id="bottom_1_3" :data="data7" ref="chart3"></radar>
          </Col>
          <Col span="6">
            <radar id="bottom_1_4" :data="data8" ref="chart4"></radar>
          </Col>
        </Row>
        <Row class="bottom-bars">
          <Col span="6">
            <double-bar id="bottom_2_1" :data="data9" ref="chart5"></double-bar>
          </Col>
          <Col span="6">
            <double-bar id="bottom_2_2" :data="data10" ref="chart6"></double-bar>
          </Col>
          <Col span="6">
            <double-bar id="bottom_2_3" :data="data11" ref="chart7"></double-bar>
          </Col>
          <Col span="6">
            <double-bar id="bottom_2_4" :data="data12" ref="chart8"></double-bar>
          </Col>
        </Row>
      </div> -->
    </Col>
    <Col style="width: 26%">
      <div class="right-1">
        <div class="right1-1">
          <span class="title"><span class="title-text">会员区域分布</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <div class="" style="height: 100%">
            <web-bar ref="chart3" :data="productBar"></web-bar>
            <!--                    <Row type="flex" justify="space-around" align="middle">
                      <Col>
                        <Row type="flex" justify="space-around" align="middle">
                          <Col>
                            <Icon type="logo-foursquare" size="50" color="#3de7c9"/>
                          </Col>
                          <Col>
                            <div style="color: #f5f5f5;font-weight: bold;font-size: 18px;">生产企业</div>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <dv-digital-flop :config="config" style="width:100px;height:30px;" />
                      </Col>
                    </Row>-->
          </div>
          <!--                  <div style="height:40%;">
                     <span class='title'><span class="title-text">经营企业</span></span>
                  </div>-->
          <!--                    <webcasts-risk ref='webcastsRisk'></webcasts-risk>-->
        </div>
      </div>
      <!--            <div class="right-1">
                <div class="right1-1">
                    <span class='title'><span class="title-text">设备安全性风险分析</span></span>
                    <span class="angle1"></span>
                    <span class="angle2"></span>
                    <span class="angle3"></span>
                    <span class="angle4"></span>
                    <device-safe-risk ref='deviceSafeRisk'></device-safe-risk>
                </div>
            </div>-->
      <div class="right-2">
        <div class="right1-1">
          <span class="title"><span class="title-text">入园量排名</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <dv-scroll-board :config="boardConfig2" style="width: 100%; height: 90%; padding: 1rem" />
          <!--                    <div class="circular">
                        <div class="canvas">
                            <div class="subtitle">话题分布</div>
                            <div class="canvasList">
                                <double-ring
                                    id='canvas_1'
                                    title="自杀抑郁"
                                    color='#00CCFF'
                                    ref="ring1"
                                ></double-ring>
                                <double-ring
                                    id='canvas_2'
                                    title="药品枪支"
                                    color='#EDCE43'
                                    ref="ring2"
                                ></double-ring>
                                <double-ring
                                    id='canvas_3'
                                    title="色情同性恋"
                                    color='#F83552'
                                    ref="ring3"
                                ></double-ring>
                            </div>
                        </div>
                        <hot-words ref="hotWords"></hot-words>
                    </div>-->
        </div>
      </div>
    </Col>
  </Row>
</template>

<script>
import { getQanYktData, getSNNodeTypelist, getYPBase, ypprobase, YpProductSalesTJ, ypretailtran } from '@/api/home'

// const chinaMap = () => import('./components/page3/chinaMap')
import Echart from './components/page3/chart.vue'
import Echart1 from './components/page3/chart1.vue'
// const barChart = () => import('./components/page3/LineMarker')
/*const channelBar = ()=> import('./components/page3/channelBar');*/
/*const distributionSolider = ()=> import('./components/page3/distributionSolider');*/
/*const pie = ()=> import('./components/pie');*/
/*const redPocket = ()=>import('./components/page3/redPocket');*/
// const radar = () => import('./components/radar')
// const doubleBar = () => import('./components/page3/doubleBar')
// const webcastsRisk = () => import('./components/page3/webcastsRisk')
/*const deviceSafeRisk = ()=> import('./components/page3/deviceSafeRisk');*/
/*const doubleRing = ()=> import('./components/page3/doubleRing');
const hotWords =() => import('./components/page3/hotWords');*/
const webBar = () => import('./components/page3/webBar.vue')
const webBar1 = () => import('./components/page3/webBar1.vue')
import CountTo from 'vue-count-to'
export default {
  name: 'page3',
  components: {
    CountTo,
    // chinaMap,
    Echart,
    Echart1,
    // webcastsRisk,
    // barChart,
    webBar,
    webBar1 // 柱图
    /*channelBar,*/
    /*distributionSolider,*/
    /*pie,*/
    /*redPocket,*/
    // radar
    // doubleBar
    /* webcastsRisk,*/
    /*deviceSafeRisk,*/
    /*       doubleRing,
        hotWords*/
  },
  data() {
    return {
      qdata: [
        {
          // 名字需要与 “common/map/fujian.js” 地图数据文件里面定义的一一对应，不能是 “福州” 或者 “闽” 之类的缩写
          name: '承德县',
          value: 2,
          upnum: 2,
          elseData: {
            // 这里放置地图 tooltip 里想显示的数据
          }
        },
        {
          name: '双桥区',
          value: 2,
          upnum: 2
        },
        {
          name: '双滦区',
          value: 2,
          upnum: 2
        },
        {
          name: '兴隆县',
          value: 2,
          upnum: 2
        },
        {
          name: '平泉县',
          value: 7,
          upnum: 5
        },
        {
          name: '滦平县',
          value: 2,
          upnum: 2
        },
        {
          name: '隆化县',
          value: 7,
          upnum: 5
        },
        {
          name: '鹰手营子矿区',
          value: 7,
          upnum: 5
        },
        {
          name: '丰宁满族自治县',
          value: 4,
          upnum: 4
        },
        {
          name: '围场满族蒙古族自治县',
          value: 4,
          upnum: 4
        },
        {
          name: '平泉市',
          value: 4,
          upnum: 4
        },
        {
          name: '宽城满族自治县',
          value: 10,
          upnum: 6
        }
      ],
      qdata1: [
        {
          // 名字需要与 “common/map/fujian.js” 地图数据文件里面定义的一一对应，不能是 “福州” 或者 “闽” 之类的缩写
          name: '承德县',
          value: 2,
          upnum: 2,
          elseData: {
            // 这里放置地图 tooltip 里想显示的数据
          }
        },
        {
          name: '双桥区',
          value: 2,
          upnum: 2
        },
        {
          name: '双滦区',
          value: 2,
          upnum: 2
        },
        {
          name: '兴隆县',
          value: 2,
          upnum: 2
        },
        {
          name: '平泉县',
          value: 7,
          upnum: 5
        },
        {
          name: '滦平县',
          value: 2,
          upnum: 2
        },
        {
          name: '隆化县',
          value: 7,
          upnum: 5
        },
        {
          name: '鹰手营子矿区',
          value: 7,
          upnum: 5
        },
        {
          name: '丰宁满族自治县',
          value: 4,
          upnum: 4
        },
        {
          name: '围场满族蒙古族自治县',
          value: 4,
          upnum: 4
        },
        {
          name: '平泉市',
          value: 4,
          upnum: 4
        },
        {
          name: '宽城满族自治县',
          value: 10,
          upnum: 6
        }
      ],
      configData2: [],
      boardConfig: {
        header: ['药品通用名称', '接种单位名称', '接种时间', '追溯信息'],
        data: [],
        headerBGC: 'transparent',
        oddRowBGC: 'transparent',
        evenRowBGC: 'transparent',
        indexHeader: '排行',
        columnWidth: [80, 280, 280, 100, 80],
        index: true,
        align: ['center']
      },
      boardConfig2: {
        header: ['景区名称', '人数'],
        data: [
          ['天元谷', 220],
          ['成山景区', 178],
          ['金岭矿山传奇景区', 156],
          ['瑞美湖心岛营地', 211],
          ['亚滦湾桃源里', 312],
          ['塔寺峪', 103],
          ['白羊峪', 98],
          ['山叶口', 56],
          ['碧螺塔', 32],
          ['昌黎国际滑沙中心', 25],
          ['公主号游船', 15],
          ['左右山谷', 62],
          ['傍水崖景区', 23]
        ],
        headerBGC: 'transparent',
        oddRowBGC: 'transparent',
        evenRowBGC: 'transparent',
        indexHeader: '排行',
        columnWidth: [60, 220, 100],
        index: true,
        align: ['center']
      },
      dataBar: {
        // 柱图数据1
        name: '会员统计',
        number: '100个',
        data: ['新增会员', '续费会员', '总计'],
        color: '192,35,42',
        value: [10000, 30000, 50000]
      },
      productBar: {
        name: '迁安市景区列表',
        number: '13个',
        data: [
          '迁安市地区',
          '秦皇岛市地区',
          '其它地区'

          // '亚滦湾桃源里',
          // '瑞美湖心岛营地',
          // '金岭矿山传奇景区',
          // '成山景区',
          // '碧螺塔',
          // '昌黎国际滑沙中心',
          // '公主号游船',
          // '左右山谷',
          // '傍水崖景区'
        ],
        color: '238,149,161',
        value: [661, 125, 208]
      },
      startVal: 0,
      endVal: 12768,
      endValLS: 3253,
      duration: 5000,
      drugConfig: {
        number: [0],
        content: '{nt}个'
      },
      config: {
        number: [13],
        content: '{nt}家'
      },
      config1: {
        number: [10],
        content: '{nt}家'
      },
      /*     data1: [
                {
                    subtitle: '675人（2345次/4533元）',
                    top: '20%',
                    data: {name: "红包转账", value: 45, color: '#0E4CFF'},
                },
                {
                    subtitle: '675人（2345次/4533元）',
                    top: '36%',
                    data: {name: "访问涉赌网络", value: 60, color: '#B405FD'},
                },
                {
                    subtitle: '675人（2345次/4533元）',
                    top: '53%',
                    data: {name: "使用涉赌应用", value: 12, color: '#FE9900'},
                },
                {
                    subtitle: '675人（2345次/4533元）',
                    top: '69%',
                    data: {name: "访问炒股网络", value: 24, color: '#FF6600'},
                },
                {
                    subtitle: '675人（2345次/4533元）',
                    top: '85%',
                    data: {name: "使用炒股应用", value: 21, color: '#7F05FD'}

                }
            ],
            data3: [
                {
                    subtitle: '675人（2345次/4533元）',
                    top: '29%',
                    data: {name: "红包转账", value: 45, color: '#0E4CFF'},
                },
                {
                    subtitle: '675人（2345次/4533元）',
                    top: '54%',
                    data: {name: "访问涉赌应用", value: 60, color: '#FE9900'},
                },
                {
                    subtitle: '675人（2345次/4533元）',
                    top: '78%',
                    data: {name: "使用炒股应用", value: 12, color: '#7F05FD'},
                },
            ],
            data2: [ // 网赌用户排名数据
                {
                    top: '16%',
                    color: '14,73,245',
                    data: [
                        {name: '王立国', value: 10},
                        {name: '李建国', value: 9},
                        {name: '董年月', value: 8},
                        {name: '高树安', value: 7},
                        {name: '李白', value: 6},
                        {name: '杜甫', value: 5},
                        {name: '刘禹锡', value: 4},
                        {name: '苏东坡', value: 3},
                        {name: '杜牧', value: 2},
                        {name: '李白', value: 1},
                    ],
                },
                {
                    top: '34%',
                    color: '170,6,243',
                    data: [
                        {name: '王立国', value: 10},
                        {name: '李建国', value: 9},
                        {name: '董年月', value: 8},
                        {name: '高树安', value: 7},
                        {name: '李白', value: 6},
                        {name: '杜甫', value: 5},
                        {name: '刘禹锡', value: 4},
                        {name: '苏东坡', value: 3},
                        {name: '杜牧', value: 2},
                        {name: '李白', value: 1},
                    ],
                },
                {
                    top: '50%',
                    color: '254,153,0',
                    data: [
                        {name: '王立国', value: 10},
                        {name: '李建国', value: 9},
                        {name: '董年月', value: 8},
                        {name: '高树安', value: 7},
                        {name: '李白', value: 6},
                        {name: '杜甫', value: 5},
                        {name: '刘禹锡', value: 4},
                        {name: '苏东坡', value: 3},
                        {name: '杜牧', value: 2},
                        {name: '李白', value: 1},
                    ],
                },
                {
                    top: '68%',
                    color: '255,102,0',
                    data: [
                        {name: '王立国', value: 10},
                        {name: '李建国', value: 9},
                        {name: '董年月', value: 8},
                        {name: '高树安', value: 7},
                        {name: '李白', value: 6},
                        {name: '杜甫', value: 5},
                        {name: '刘禹锡', value: 4},
                        {name: '苏东坡', value: 3},
                        {name: '杜牧', value: 2},
                        {name: '李白', value: 1},
                    ],
                },
                {
                    top: '85%',
                    color: '127,5,253',
                    data: [
                        {name: '王立国', value: 10},
                        {name: '李建国', value: 9},
                        {name: '董年月', value: 8},
                        {name: '高树安', value: 7},
                        {name: '李白', value: 6},
                        {name: '杜甫', value: 5},
                        {name: '刘禹锡', value: 4},
                        {name: '苏东坡', value: 3},
                        {name: '杜牧', value: 2},
                        {name: '李白', value: 1},
                    ],
                },
            ],
            data4: [
                {
                    top: '30%',
                    color: '14,73,245',
                    data: [
                        {name: '王立国', value: 10},
                        {name: '李建国', value: 9},
                        {name: '董年月', value: 8},
                        {name: '高树安', value: 7},
                        {name: '李白', value: 6},
                        {name: '杜甫', value: 5},
                        {name: '刘禹锡', value: 4},
                        {name: '苏东坡', value: 3},
                        {name: '杜牧', value: 2},
                        {name: '李白', value: 1},
                    ],
                },
                {
                    top: '57%',
                    color: '254,153,0',
                    data: [
                        {name: '王立国', value: 10},
                        {name: '李建国', value: 9},
                        {name: '董年月', value: 8},
                        {name: '高树安', value: 7},
                        {name: '李白', value: 6},
                        {name: '杜甫', value: 5},
                        {name: '刘禹锡', value: 4},
                        {name: '苏东坡', value: 3},
                        {name: '杜牧', value: 2},
                        {name: '李白', value: 1},
                    ],
                },
                {
                    top: '83%',
                    color: '127,5,253',
                    data: [
                        {name: '王立国', value: 10},
                        {name: '李建国', value: 9},
                        {name: '董年月', value: 8},
                        {name: '高树安', value: 7},
                        {name: '李白', value: 6},
                        {name: '杜甫', value: 5},
                        {name: '刘禹锡', value: 4},
                        {name: '苏东坡', value: 3},
                        {name: '杜牧', value: 2},
                        {name: '李白', value: 1},
                    ],
                },
            ],
            data5: {
                title: '社会应用分析',
                position: ['5%', '14%'],
                center: ['50%', '60%'],
                indicator: [
                    {text: '微信'},
                    {text: 'QQ'},
                    {text: '微博'},
                    {text: '陌陌'},
                    {text: 'INS'},
                    {text: '抖音'}
                ],
                data: [
                    {
                        name: '安装量',
                        color: '#0DE4EE',
                        value: [100, 8, 0.40, -80, 2000, 345]
                    },
                    {
                        name: '使用时长',
                        color: '#0D88F3',
                        value: [60, 5, 0.30, -100, 1500, 232]
                    }
                ]
            },
            data6: {
                title: '涉军信息分析',
                position: ['5%', '14%'],
                center: ['50%', '60%'],
                indicator: [
                    {text: '微信'},
                    {text: 'QQ'},
                    {text: 'SNS'},
                    {text: '漂流瓶'},
                    {text: '陌陌'},
                    {text: '摇一摇'},
                    {text: 'INS'},
                    {text: '抖音'},
                ],
                data: [
                    {
                        name: '个人信息涉军',
                        color: '#6514FF',
                        value: [100, 8, 0.40, -80, 2000, 345, 12, 654]
                    },
                    {
                        name: '内容涉军',
                        color: '#B370FD',
                        value: [60, 5, 0.30, -100, 1500, 232, 432, 43]
                    }
                ]
            },
            data7: {
                title: '交友方式分析',
                position: ['5%', '14%'],
                center: ['50%', '60%'],
                indicator: [
                    {text: '面对面'},
                    {text: '手机号'},
                    {text: '手机联系人'},
                    {text: '摇一摇'},
                    {text: '微博'},
                    {text: '微信'},
                    {text: '名片'},
                    {text: '快手'},
                    {text: 'INS'},
                    {text: '扫一扫'},
                    {text: '附近的人'},
                    {text: '漂流瓶'},
                ],
                data: [
                    {
                        name: '主动',
                        color: '#0096FE',
                        value: [100, 8, 0.40, -80, 2000, 345, 123, 21, 34, 54, 431, 876]
                    },
                    {
                        name: '被动',
                        color: '#9EEAFF',
                        value: [60, 5, 0.30, -100, 1500, 232, 78, 32, 567, 43, 765, 432,]
                    }
                ]
            },
            data8: {
                title: '群属性分析',
                position: ['5%', '14%'],
                center: ['50%', '60%'],
                indicator: [
                    {text: '博彩群'},
                    {text: '涉赌群'},
                    {text: '营销群'},
                    {text: '其他'},
                    {text: '相亲群'},
                    {text: '涉黄群'},
                ],
                data: [
                    {
                        name: '微信',
                        color: '#FD9800',
                        value: [100, 8, 0.40, -80, 2000, 345],
                    },
                    {
                        name: 'QQ',
                        color: '#FDC673',
                        value: [60, 5, 0.30, -100, 1500, 232]
                    }
                ]
            },
            data9: {
                title: '重点关注用户',
                data: [
                    {
                        name: '个数',
                        color: '#00CCFF',
                        value: ['112', '212', '42', '232', '123', '67'],
                    },
                    {
                        name: '使用时长',
                        color: '#142AFE',
                        value: ['112', '212', '42', '232', '123', '67']
                    }
                ]
            },
            data10: {
                title: '重点关注用户',
                data: [
                    {
                        name: '个人信息涉军应用个数',
                        color: '#6514FF',
                        value: ['112', '212', '42', '232', '123', '67'],
                    },
                    {
                        name: '涉军内容条数',
                        color: '#B370FD',
                        value: ['112', '212', '42', '232', '123', '67']
                    }
                ]
            },
            data11: {
                title: '重点关注异常交友用户',
                data: [
                    {
                        name: '主动',
                        color: '#05467D',
                        value: ['112', '212', '42', '232', '123', '67'],
                    },
                    {
                        name: '被动',
                        color: '#52B8FF',
                        value: ['112', '212', '42', '232', '123', '67']
                    }
                ]
            },
            data12: {
                title: '重点关注用户',
                data: [
                    {
                        name: '个数',
                        color: '#FD9800',
                        value: ['112', '212', '42', '232', '123', '67'],
                    },
                    {
                        name: '使用时长',
                        color: '#FDC673',
                        value: ['112', '212', '42', '232', '123', '67']
                    }
                ]
            },
            pieData1: {// 饼图数据1
                title: "TOP数据1",
                color: '#2C7BFE',

                data: [
                    {
                        value: 60,
                        name: '分类1',
                        itemStyle: {
                            color: '#1456FE'
                        }

                    },
                    {
                        value: 20,
                        name: '分类2',
                        itemStyle: {
                            color: '#00CCFF'
                        }
                    },
                    {
                        value: 80,
                        name: '分类3',
                        itemStyle: {
                            color: '#142AFE'
                        }
                    },
                    {
                        value: 40,
                        name: '分类4',
                        itemStyle: {
                            color: '#1493FE'
                        }
                    },
                    {
                        value: 40,
                        name: '分类5',
                        itemStyle: {
                            color: '#252448'
                        }
                    }
                ],
            },
            pieData2: {// 饼图数据1
                title: "TOP数据2",
                color: '#2C7BFE',

                data: [
                    {
                        value: 60,
                        name: '分类1',
                        itemStyle: {
                            color: '#142AFE'
                        }

                    },
                    {
                        value: 20,
                        name: '分类2',
                        itemStyle: {
                            color: '#1493FE'
                        }
                    },
                    {
                        value: 80,
                        name: '分类3',
                        itemStyle: {
                            color: '#252448'
                        }
                    },
                    {
                        value: 40,
                        name: '分类4',
                        itemStyle: {
                            color: '#00CCFF'
                        }
                    },
                    {
                        value: 40,
                        name: '分类5',
                        itemStyle: {
                            color: '#1456FE'
                        }
                    }
                ],
            },
            pieData3: {// 饼图数据1
                title: "TOP数据3",
                color: '#2C7BFE',

                data: [
                    {
                        value: 60,
                        name: '分类1',
                        itemStyle: {
                            color: '#1493FE'
                        }

                    },
                    {
                        value: 20,
                        name: '分类2',
                        itemStyle: {
                            color: '#142AFE'
                        }
                    },
                    {
                        value: 80,
                        name: '分类3',
                        itemStyle: {
                            color: '#1456FE'
                        }
                    },
                    {
                        value: 40,
                        name: '分类4',
                        itemStyle: {
                            color: '#00CCFF'
                        }
                    },
                    {
                        value: 40,
                        name: '分类5',
                        itemStyle: {
                            color: '#252448'
                        }
                    }
                ],
            },*/
      resizeFn: null,
      timerData: null
    }
  },
  methods: {
    getQanYktCurrentDayData() {
      const date = new Date()
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      const currentDate = `${year}-${month}-${day}`
      const data = {
        limit: 100,
        offset: 1,
        btmzcrq: currentDate,
        btmyxrq: currentDate
      }
      getQanYktData(data).then((res) => {
        // console.log(res)

        this.boardConfig2.data = res.data.map((item) => {
          return [item.bteusername, item.inserttime]
        })
        //检测到数据更新重新赋值config 没有这步 dv-scroll-board标签会加载页面没有数据
        this.boardConfig2 = { ...this.boardConfig2 }
        // this.boardConfig2.data.push([bteusername, inserttime])
      })
    },
    getSNNodeTypelist() {
      getSNNodeTypelist({ classtype: '3' }).then((res) => {
        for (let item of res.data) {
          if (item.name === '生产加工环节') {
            this.config.number = [item.cc]
            this.config = { ...this.config }
          }
          if (item.name === '批发环节') {
            this.endVal = item.cc
          }
          if (item.name === '零售环节') {
            this.endValLS = item.cc
          }
          if (item.name === '种植环节') {
            this.drugConfig.number = [item.cc]
            this.drugConfig = { ...this.drugConfig }
          }
        }
      })
    },
    getYPBase() {
      getYPBase().then((res) => {
        this.dataBar.data = res.data.map((item) => {
          return item.PIECESNAME
        })
        this.dataBar.value = res.data.map((item) => {
          return item.num
        })
        console.log(this.dataBar)
      })
    },
    YpProductSalesTJ() {
      YpProductSalesTJ().then((res) => {
        this.configData2 = res.data.reverse()
      })
    },
    ypprobase() {
      ypprobase().then((res) => {
        this.productBar.data = res.data.map((item) => {
          return item.CORP_NAME
        })
        this.productBar.value = res.data.map((item) => {
          return item.WEIGHT
        })
      })
    },
    ypretailtran() {
      ypretailtran().then((res) => {
        this.boardConfig2.data = res.data.map((item) => {
          return Object.assign([], [item.compname, item.totalnum, item.per])
        })
        this.boardConfig2 = { ...this.boardConfig2 }
      })
    }
  },
  created() {
    // this.getSNNodeTypelist()
    // this.getYPBase()
    // this.YpProductSalesTJ()
    // this.ypprobase()
    // this.ypretailtran()
  },
  mounted() {
    this.timerData = setInterval(() => {
      this.getQanYktCurrentDayData()
    }, 5 * 60 * 1000)
    this.getQanYktCurrentDayData()

    this.resizeFn = this.$debounce(() => {
      // 通过捕获系统的onresize事件触发我们需要执行的事件
      this.$refs.channelBar1.setChart()
      this.$refs.distributionSolider1.setChart()
      this.$refs.channelBar2.setChart()
      this.$refs.distributionSolider2.setChart()
      //this.$refs.pies.setPies();
      this.$refs.redPocket.setPocket()
      this.$refs.webcastsRisk.setWebcasts()
      this.$refs.deviceSafeRisk.setDeviceSafe()
      this.$refs.ring1.drawRing()
      this.$refs.ring2.drawRing()
      this.$refs.ring3.drawRing()
      for (let i = 1; i < 9; i++) {
        this.$refs['chart' + i].setChart()
      }
      this.$refs.chinaMap.setMap()
      this.$refs.hotWords.setChart()
    }, 500)
    window.addEventListener('resize', this.resizeFn)
  },
  beforeDestroy() {
    clearInterval(this.timerData)
    this.timerData = null
    window.removeEventListener('resize', this.resizeFn)
  }
}
</script>

<style lang="less" scoped>
.page3 {
  height: 100%;
  width: 100%;
  padding: 14px 20px 20px;
  overflow: hidden;
  .ivu-col {
    height: 100%;
    float: left;
  }

  .left,
  .right1-1,
  .center-bottom {
    height: 100%;
    border: 1px solid #0d2451;
    position: relative;

    #left_5 {
      height: 100%;
      width: 45%;
      float: left;
    }

    #left_6 {
      height: 100%;
      width: 55%;
      float: left;
    }

    .circular {
      height: 100%;

      .canvas {
        height: 100%;
        width: 30%;
        float: left;

        .subtitle {
          font-size: 12px;
          font-weight: bold;
          color: #fff;
          height: 25px;
          padding: 10px 0;
          text-align: center;
        }

        .canvasList {
          height: calc(~'100% - 25px');
          text-align: center;
        }
      }
    }

    .left1 {
      border-bottom: 1px solid #0d2451;
    }

    .title {
      position: absolute;
      display: inline-block;
      color: #6eddf1;
      border: 2px solid #6eddf1;
      height: 18px;
      padding: 0 2px;
      left: 50%;
      transform: translate(-50%, -50%);

      .title-text {
        position: relative;
        font-size: 16px;
        background: #09102e;
        display: inline-block;
        padding: 0 4px;
        transform: translateY(-5px);
      }
    }
  }

  .center-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    .echart {
      flex: 0.5;
    }
    .echart1 {
      flex: 0.5;
    }
  }

  .center-bottom {
    height: 40%;

    .bottom-radars {
      height: 55%;
    }

    .bottom-bars {
      height: 45%;
    }
  }

  .right-1 {
    height: 60%;

    .right1-1 {
      height: 96%;
    }
  }

  .right-2 {
    height: 40%;
  }
}
</style>
